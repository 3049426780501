<template>
  <div class="newest-wrap">
    <div class="newest-header">
      <span class="newest-header-title">{{$t('taskhub.public.title')}}</span>
    </div>
    <div class="newest-content">
      <TemplateList
        :columnAttr="columnAttr"
        :dataList="latestList"
        :isLoading="loading"
        :isIndent="false"
        :header="false"
        class="selected-list"
        :page="page"
      ></TemplateList>
    </div>
    <backTo-top isContainer=".router-view__wrap"></backTo-top>
  </div>
</template>

<script>
import TemplateList from '@/components/common/TemplateList'
import { getTaskSelected } from 'services/public'
import { mapMutations } from 'vuex'

export default {
  name: 'History',
  components: {
    TemplateList
  },
  computed: {
    columnAttr() {
      const keyMap = [
        {
          label: '环境_时间',
          props: 'env_time',
          span: 6
        },
        {
          label: '类型_分值',
          props: 'type_score',
          span: 4
        },
        {
          label: '名称_类型_标签',
          props: 'name_type_tags',
          span: 7
        },
        {
          label: '哈希',
          props: 'hash',
          span: 7
        }
      ]
      return keyMap
    }
  },
  data() {
    return {
      totalSize: 0,
      pageSize: 50,
      currentPage: Number(this.$route.query.page) || 1,
      latestList: [],
      loading: true,
      currentType: this.$route.query.type || 'file',
      page: 'newest',
      isMounted: false,
      newest: {
        tasktype: '',
        tag: '',
        score: '',
        filetype: ''
      },
      isFilter: false,
      switchList: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '文件',
          value: 'file'
        },
        {
          label: 'URL',
          value: 'url'
        }
      ],
      filetype_list: [],
      initInterval: null,
      filter: {}
    }
  },
  methods: {
    ...mapMutations({
      setOptions: 'publiclist/SET_OPTIONS'
    }),
    refresh() {
      this.isMounted = false
      this.$nextTick(() => {
        this.isMounted = true
      })
    },
    async getTaskSelected() {
      this.loading = true
      await getTaskSelected()
        .then((res) => {
          this.loading = false
          this.latestList = res.task
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  mounted() {
    this.getTaskSelected()
  }
}
</script>

<style lang="scss" scoped>
.newest-wrap {
  min-height: calc(100vh - 116px);
  .newest-header {
    line-height: 46px;
    height: 46px;
    padding: 2px 24px;
    display: flex;
    justify-content: space-between;
    .newest-header-title {
      font-size: 18px;
      color: var(--color-text-1);
      font-weight: 500;
    }
    .newest-header-info {
      font-size: 14px;
    }
  }
  .newest-content {
    padding: 0px 24px 6px;
    // min-height: calc(100vh - 256px);
    display: flex;
    flex-direction: column;
    .newest-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 0px 6px 0;
    }
    .selected-list {
      margin-top: 0;
    }
  }
  .pagination-wrap {
    padding: 10px 3px 24px;
    text-align: right;
  }
  .list-pagination {
  }
}
</style>
